import L from "L";

const COLORS = [
  /// available "outside" crashpad colors ///
  `black`,
  `charcoal-gray`,

  /// all other available crashpad colors ///
  `baby-blue`,
  `blaze-orange`,
  `brown`,
  `burgundy`,
  `coral`,
  `dark-tan`,
  `desert-camo`,
  `desert-tiger-stripe`,
  `digital-camo`,
  `duck-hunter-camo`,
  `forest-green`,
  `fuscia`,
  `golden-yellow`,
  `grasshopper-green`,
  `ice-blue`,
  `kelly-green`,
  `marpat-camo`,
  `mint-green`,
  `navy`,
  `neon-blue`,
  `neon-green`,
  `neon-pink`,
  `neon-yellow`,
  `olive-green`,
  `orange`,
  `peach`,
  `plum`,
  `purple`,
  `red`,
  `royal-blue`,
  `rust`,
  `sand-tan`,
  `silver`,
  `teal`,
  `white`,
  `woodland-camo`,
  `yellow`,
];
const OUTSIDE_COLORS = [
  /// colors available for crashpad outside ///
  `black`,
  `charcoal-gray`,
];
const ELEMENTS = {
  LAYOUT: L(`.crashpad-layout`).all(),
  GROUPS: L(`[group]`)
    .all()
    .reduce((groups, el) => {
      const group = L(el).get(`group`);
      groups[group] = [...(groups[group] || []), el];
      return groups;
    }, {} as Record<string, HTMLElement[]>),
  HOVER: L(`.crashpad-hover`).all(),
  INFO: L(`.info`).all(),
  HELP: L(`.help`).all(),
  RANDOM: L(`.random`).all(),
  RESET: L(`.reset`).all(),
  SHARE: L(`.share`).all(),
  CLIPBOARD: L(`.clipboard-message`).all(),
};
const DEFAULT_COLORS: Record<string, string> = Object.assign(
  {},
  ...Object.entries(ELEMENTS.GROUPS).map(([group, elements]) => ({
    [group]: L(elements).get(`color`),
  }))
);
const LAYOUTS: string[] = [`A`, `B`];
const DEFAULT_LAYOUT: string = L(ELEMENTS.LAYOUT).get(`layout`);

const STATE_KEY_ALIASES = {
  background: `bg`,
  "accent-1": `a1`,
  "accent-2": `a2`,
  layout: `lo`,
  outside: `os`,
};
const STATE_VALUE_OPTIONS = {
  background: COLORS,
  "accent-1": COLORS,
  "accent-2": COLORS,
  layout: LAYOUTS,
  outside: OUTSIDE_COLORS,
};

// Initialize state:
L.state
  .configure({
    background: { default: `navy`, options: COLORS, aliases: [`bg`] },
    "accent-1": { default: `rust`, options: COLORS, aliases: [`a1`] },
    "accent-2": { default: `burgundy`, options: COLORS, aliases: [`a2`] },
    layout: { default: `B`, options: LAYOUTS, aliases: [`lo`] },
    outside: { default: `black`, options: COLORS, aliases: [`os`] },
  })
  .load(() => {
    L(ELEMENTS.LAYOUT).set(`layout`, L.state.get(`layout`));
    Object.keys(ELEMENTS.GROUPS).forEach((group) =>
      L(ELEMENTS.GROUPS[group]).set(`color`, L.state.get(group))
    );
  });

/// Image Preloading -- Performance Enhancement ///
// TODO make images not depend on external resources
function preloadColors(colors: string[]): void {
  colors.forEach((color) => {
    new Image().src =
      `https://cdn.shopify.com/s/files/1/0581/7001/t/12/assets/` +
      color +
      `-large.jpg`;
  });
}
preloadColors(Object.values(DEFAULT_COLORS).concat([`black`]));
// wait to load non-default colors to optimize loading time for defaults
setTimeout(() => preloadColors(COLORS), 1000);

/// UI Interaction ///
// Change crashpad color on crashpad section click:
L(
  [].concat(...Object.entries(ELEMENTS.GROUPS).map(([_, elements]) => elements))
).on(`click`, (ev) => {
  ev.stopPropagation();
  const group = L(ev.target).get(`group`);
  L.state.set(
    group,
    L(ELEMENTS.GROUPS[group]).cyc(
      `color`,
      group === `outside` ? OUTSIDE_COLORS : COLORS
    )
  );
  L.state.write({ push: true });
  L(ELEMENTS.INFO).rem(400);
});

// Change crashpad side color on side click:
L(ELEMENTS.HOVER).on(`click`, (ev) => {
  ev.stopPropagation();
  const group = `outside`;
  L.state.set(group, L(ELEMENTS.GROUPS[group]).cyc(`color`, OUTSIDE_COLORS));
  L.state.write({ push: true });
  L(ELEMENTS.INFO).rem(400);
});

// Change layout on click outside crashpad:
L(document.body).on(`click`, (ev) => {
  L(ELEMENTS.LAYOUT).cyc(`layout`, LAYOUTS);
  L(ELEMENTS.INFO).rem(400);
  L.state.set(`layout`, L(ELEMENTS.LAYOUT).get(`layout`));
  L.state.write({ push: true });
});

// Show help text:
L(ELEMENTS.HELP).on(`click`, (ev) => {
  ev.stopPropagation();
  L(ELEMENTS.INFO).rem(false, 400);
});

// Randomize:
L(ELEMENTS.RANDOM).on(`click`, (ev) => {
  ev.stopPropagation();
  const usedColors = new Set();
  Object.entries(ELEMENTS.GROUPS).forEach(([group, elements]) => {
    const colors = group === `outside` ? OUTSIDE_COLORS : COLORS;
    const newColor = L.state.set(
      group,
      L(elements).rnd(
        `color`,
        colors.filter((clr) => !usedColors.has(clr))
      )
    );
    usedColors.add(newColor); // ensure same color isn't used twice
  });
  L.state.set(`layout`, L(ELEMENTS.LAYOUT).rnd(`layout`, LAYOUTS));
  L.state.write({ push: true });
  L(ELEMENTS.INFO).rem(400);
});

// Reset:
L(ELEMENTS.RESET).on(`click`, (ev) => {
  ev.stopPropagation();
  Object.entries(DEFAULT_COLORS).forEach(([group, color]) =>
    L.state.set(group, L(ELEMENTS.GROUPS[group]).set(`color`, color as string))
  );
  L.state.set(`layout`, L(ELEMENTS.LAYOUT).set(`layout`, DEFAULT_LAYOUT));
  L.state.write({ push: true });
  L(ELEMENTS.INFO).rem(400);
});

// Share:
L(ELEMENTS.SHARE).on(`click`, (ev) => {
  ev.stopPropagation();
  L.copy(L.state.url({ short: true }));
  L(ELEMENTS.INFO).rem(400);
  L(ELEMENTS.CLIPBOARD).rem(false, 400);
  setTimeout(() => L(ELEMENTS.CLIPBOARD).rem(400), 1500);
});

// Update help text if we`re on a phone:
if (window.innerHeight > window.innerWidth) {
  // simply check for portrait mode (for now)
  ELEMENTS.INFO.forEach((el) => {
    el.innerHTML = el.innerHTML.replace(`click`, `tap`).replace(`hover`, `tap`);
  });
}
